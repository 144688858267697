import SnackbarModule from "@/store/SnackbarModule"
import {getModule} from "vuex-module-decorators"

let snackbarModule: SnackbarModule = getModule(SnackbarModule)

type ResponseCode = keyof typeof responseMessages;

export function handleResponseCode(status: ResponseCode) {
    if (responseMessages[status] != undefined) {
        snackbarModule.makeToast(responseMessages[status])
    }
}

const responseMessages = {
    "PASSWORD_RECOVERY_TOKEN_EXPIRED": 'Este token de restablecimiento ha expirado.',
    "AUTH_TOKEN_EXPIRED": 'El token de autenticacion ha expirado',
}